import React from "react";
import { useSelector } from "react-redux";
import { Route, withRouter } from "react-router-dom";

const Breadcrumb = (props) => {
  const users = useSelector((state) => state.UserReducer.users);
  const roles = useSelector((state) => state.RoleReducer.roles);
  const targetedContent = useSelector(
    (state) => state.TargetedContentReducer.targetedContent
  );
  const allContent = useSelector(
    (state) => state.ResourceImageUploaderReducer.allContent
  );
  const targetGroups = useSelector(
    (state) => state.TargetGroupReducer.targetGroups
  );
  const campaigns = useSelector((state) => state.CampaignReducer.campaigns);

  return (
    <Route
      {...props}
      path="*"
      render={(routeProps) => {
        let parts = routeProps.location.pathname.split("/");
        let length = parts.length;

        let id = parts[length - 1];
        if (id) {
          if (users[id]) {
            parts.splice(length - 1, 1, users[id].name);
          }

          if (roles[id]) {
            parts.splice(length - 1, 1, roles[id].name);
          }

          if (Array.isArray(allContent)) {
            const contentItem = allContent.filter((c) => c.ContentId === id);
            if (contentItem && contentItem.length > 0) {
              parts.splice(length - 1, 1, contentItem[0].ContentTitle);
            }
          } else if (Object.keys(allContent).length > 0) {
            const contentItem = Object.values(allContent).map(
              (c) => c.ContentId === id
            );
            parts.splice(length - 1, 1, contentItem[0].ContentTitle);
          }

          const targetedContentItem = targetedContent.filter(
            (tc) => tc.ContentId === id
          );
          if (targetedContentItem && targetedContentItem.length > 0) {
            parts.splice(length - 1, 1, targetedContentItem[0].ContentTitle);
          }

          const targetGroupItem = targetGroups.filter(
            (tg) => tg.TargetGroupId === id
          );
          if (targetGroupItem && targetGroupItem.length > 0) {
            parts.splice(length - 1, 1, targetGroupItem[0].TargetGroupName);
          }

          if (campaigns[id]) {
            parts.splice(length - 1, 1, campaigns[id].Name);
          }
        }

        const isChoice = parts[parts.length - 2].toLocaleLowerCase() == "choice"
        const choiceUrl =window.location.pathname.split("/").pop()
        const place =isChoice ? choiceUrl : parts[parts.length - 1];
        parts = parts.slice(1, parts.length - 1);

        if (length > 2) {
          return (
            <div className={props.styles.breadcrumb}>
              {parts.map(props.crumb)}
              {decodeURIComponent(place).replace('/', '')}
            </div>
          );
        }
      }}
    />
  );
};

const hoc = withRouter(Breadcrumb);

// EXPORT COMPONENT
export { hoc as Breadcrumb };
