// React
import React, { useState } from "react";

import Papa from "papaparse";

// UI and Styling
import { Button, FormHelperText, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "./ContentUploadSegments.module.scss";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import FileDrop from "../../components/file-drop/FileDrop";

// Other
import { FileType } from "../../state/constants/FileType";
import { UUID } from "../../helpers/uuid";

const CSVUploadSegment = ({
  csvData,
  setCsvData,
  setDataChanged,
  title,
  targetsEdited,
  isColumnsUnique,
  setTargetsEdited,
  isEditing,
  description,
  templateFileName,
  expectedColumns,
  setCSVInvalid,
  sortProperties = [],
  customValidation,
  stripUnexpectedColumns,
  isNullDesc
}) => {
  const [csvFile, setCsvFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const validateColumns = (rowHeaders, columns) => {
    return rowHeaders.length === columns.length &&
      rowHeaders.sort().every(function (value, index) {
        return value === columns.sort()[index];
      });
  };

  // sort data based on an array of properties
  const sortDataByProperties = (data, properties) => {
    return data.sort((a, b) => {
      return properties.forEach((property, i) => {
        if (a[properties[i]] < b[properties[i]]) {
          return -1;
        }
        if (a[properties[i]] > b[properties[i]]) {
          return 1;
        }
        return 0;
      });
    });
  }

  const checkNullDesc = (campaignData) =>{
    const isNullDesc = campaignData.find(item=> item.Description == null)
    return isNullDesc ? true : false
  }

  const ParseFileUpload = (files) => {
    const file = files[0];

    if (file !== null) {
      setCsvFile([file]);

      const fileReader = new FileReader();

      const eventListener = (event) => {
        let fileData = event.target.result;
        // Remove any UTF-8 bytecodes. ï»¿ - This represents the string representation of a UTF-8 bytecode
        fileData = fileData.replace(/\uFEFF|0xFEFF|ï»¿/g, "");


        Papa.parse(fileData, {
          header: isColumnsUnique ? false : true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data.length > 0) {
              let rowHeaders;
              if (isColumnsUnique) {
                rowHeaders = Object.values(results.data[0]);
              } else {
                rowHeaders = Object.keys(results.data[0]);
              }
              let localData = results.data;
              if (sortProperties.length) {
                sortDataByProperties(localData, sortProperties);
              }

              // Replace empty strings with null
              localData = localData.map((row, index) => {
                Object.entries(row).forEach(([key, value]) => {
                  if (value === "") {
                    row[key] = null;
                  }
                });
                return row;
              });

              // Remove columns that are not in the expected columns array
              if (stripUnexpectedColumns === true) {
                localData = localData.map((row, index) => {
                  Object.entries(row).forEach(([key, value]) => {
                    if (!expectedColumns.includes(key)) {
                      delete row[key];
                    }
                  });
                  return row;
                });

                rowHeaders = rowHeaders.filter((header) => {
                  return expectedColumns.includes(header);
                });
              }

              if (!targetsEdited) {
                setTargetsEdited(true);
              }

              // duplicate columns showing error
              if (isColumnsUnique) {
                const keys = localData.shift();
                const formattedLocalData = localData.reduce((agg, arr) => {
                  agg.push(arr.reduce((obj, item, index) => {
                    obj[keys[index]] = item;
                    return obj;
                  }, {}));
                  return agg;
                }, [])
                setCsvData(formattedLocalData);
              } else {
                setCsvData(localData);
              }
              setDataChanged(true);

              if (expectedColumns && !validateColumns(rowHeaders, expectedColumns)) {
                setCSVInvalid(true);
                setErrorMessage(
                  `Columns in uploaded file don't match the template. Expected columns are: ${expectedColumns.join(', ')}`
                );
              } else {
                setCSVInvalid(false);
                setErrorMessage("");
              }
              if(isNullDesc){
                if(checkNullDesc(localData)){
                  setCSVInvalid(true);
                  setErrorMessage(
                    `Description for campaigns can't be null, Please enter the description.`
                  );
                }
              }
              if (customValidation) {
                const customValidationErrors = customValidation(localData);
                if (customValidationErrors.length > 0) {
                  setCSVInvalid(true);
                  setErrorMessage(
                    `Errors in uploaded file:\n ${customValidationErrors.join('\n')}`
                  );
                }
              }
            }
            else {
              setCSVInvalid(true);
              setErrorMessage("Invalid CSV, please check that the file is in the correct format.");
            }
          },
        });


      };

      fileReader.addEventListener("load", eventListener);

      fileReader.readAsBinaryString(file);
    }
  };

  const handleDownloadOnClick = (event) => {
    window.open(`/templates/${templateFileName}`, "_self");
  };

  // Get the markup for the main content
  const getIdGrid = () => {
    const ids = Object.keys(csvData).length > 0 ? csvData : [];

    // Holy Guac Batman
    const columns = !(!Array.isArray(csvData) || !csvData.length)
      ? Object.keys(csvData[0])
      : [];

    const userTableOptions = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 50, 100, 200],

      viewColumns: false,
      filterType: "checkbox",
      elevation: 0,
      selectableRows: isEditing ? "multiple" : "none",
      print: false,
      download: templateFileName ? true : false,
      downloadOptions: {
        filename: "content-migration.csv",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };
    if (ids.length > 0) {
      return (
        <MUIDataTable
          title="Content"
          data={ids}
          columns={columns}
          options={userTableOptions}
        />
      );
    }
    else {
      return null;
    }
  };

  return (
    <CustomCard
      title={title}
      actionButton={
        templateFileName
          ? [
            <Button
              key={UUID()}
              className={styles.buttonStyle}
              variant="outlined"
              color="secondary"
              onClick={(event) => handleDownloadOnClick(event)}
            >
              Download Template
            </Button>,
          ]
          : []
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            {description}
          </Typography>
          <div className={styles.fileDrop}>
            <FileDrop
              message={"Upload CSV"}
              onFilesAdded={(file) => ParseFileUpload(file)}
              file={csvFile}
              errorMessage={""}
              enforceTypes={[
                FileType.csv,
                FileType.xls,
                FileType.plain,
                FileType.xcsv,
                FileType.appcsv,
                FileType.appxcsv,
                FileType.comma,
                FileType.xcomma,
                FileType.tab,
              ]}
              enforceTypesMessage={"File must be a csv"}
            />
          </div>
        </Grid>
        {errorMessage !== "" ? (
          <Grid item xs={12}>
            <FormHelperText error={true} style={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</FormHelperText>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {getIdGrid()}
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default CSVUploadSegment;
